const { useState, useEffect } = require('react');

export const useConsentState = () => {
    const [consentState, setConsentState] = useState({
        unclassified: false,
        essential: false,
        performance: false,
        analytics: false,
        advertising: false,
    });

    useEffect(() => {
        // Check if window is defined before accessing it
        if (typeof window !== 'undefined' && window?.Termly?.getConsentState()) {
            setConsentState(window.Termly.getConsentState());
        }
    }, []);
    return {
        unclassified: consentState.unclassified,
        essential: consentState.essential,
        performance: consentState.performance,
        analytics: consentState.analytics,
        advertising: consentState.advertising,
    };
};
