import { useContext, useCallback } from 'react';
import Link from 'components/atoms/Link';
import styled from 'styled-components';
import Drawer from '@material-ui/core/Drawer';
import Collapsible from 'react-collapsible';
import PropTypes from 'prop-types';

import StoreContext from 'context/SiteContext';

import { BodyText } from 'components/atoms/Typography';
import { CaretDownIcon } from 'components/atoms/Icons';
import { useRouter } from 'next/router';
import SiteContext from 'context/SiteContext';
import Image from 'components/atoms/Image';
import algoliasearch from 'algoliasearch';
import { Configure, InstantSearch } from 'react-instantsearch';
import Autocomplete from '../SearchPage/Autocomplete';

import breakpoints from 'styles/breakpoints';
import { Fragment } from 'react';
import { isAlgoliaAnalyticsEnabled } from 'helpers/algolia';
import { useConsentState } from 'hooks/useConsentState';

const Menu = styled.div`
    padding-top: var(--header-height, 57px);
    @media screen and (${breakpoints.lg}) {
        padding-top: var(--header-height, 80px);
    }
    &:before {
        content: '';
        height: 1px;
        width: 100%;
        background-color: rgb(var(--border-color));
        position: fixed;
        top: var(--header-height);
        @media screen and (${breakpoints.lg}) {
            top: var(--header-height, 80px);
        }
    }
    .Collapsible__trigger {
        svg {
            transition: all 0.3s ease-in-out;
        }
        &.is-open {
            svg {
                transform: scale(-1);
            }
        }
    }
    .Collapsible__contentOuter {
        transition: all 0.3s ease-in-out !important;
    }
`;

const SidebarMenu = () => {
    const router = useRouter();

    const {
        store: { settings, sidebarMenuOpen },
        setSidebarMenuOpen,
    } = useContext(StoreContext);

    const closeMenu = useCallback(() => {
        setSidebarMenuOpen(false);
    }, [setSidebarMenuOpen]);

    return sidebarMenuOpen ? (
        <Drawer
            anchor="left"
            open={sidebarMenuOpen}
            onClose={() => setSidebarMenuOpen(false)}
            classes={{ paper: 'w-full' }}
        >
            <Menu className="flex flex-col justify-between items-center h-full pb-xxs">
                <div className="w-full px-m-md pt-4">
                    {!router?.asPath?.includes('/search') && (
                        <div className="">
                            <SearchForm />
                        </div>
                    )}
                    {(settings?.header?.links || settings?.header?.rightLinks) && (
                        <div className="flex flex-col w-full py-5">
                            {settings?.header?.links?.map(link => {
                                if (link?.sublinks?.length > 0) {
                                    return (
                                        <Collapsible
                                            className="col-span-6"
                                            trigger={
                                                <button className="w-full flex flex-wrap text-left py-3">
                                                    <div className="flex-1">
                                                        <BodyText
                                                            font="subheading"
                                                            className="uppercase tracking-widest"
                                                            fontSize="18px"
                                                        >
                                                            {link.title}
                                                        </BodyText>
                                                    </div>
                                                    <div className="ml-10 self-center">
                                                        <CaretDownIcon width="12" />
                                                    </div>
                                                </button>
                                            }
                                            key={link._uid}
                                        >
                                            <SubMenu link={link} closeMenu={closeMenu} />
                                        </Collapsible>
                                    );
                                } else {
                                    return (
                                        <Link
                                            key={link._uid}
                                            href={link.url || '#'}
                                            onClick={closeMenu}
                                        >
                                            <BodyText
                                                font="subheading"
                                                as="span"
                                                className="block uppercase tracking-widest py-3"
                                                fontSize="18px"
                                            >
                                                {link.title}
                                            </BodyText>
                                        </Link>
                                    );
                                }
                            })}
                            {settings?.header?.rightLinks?.map(link => {
                                if (link?.sublinks?.length > 0) {
                                    return (
                                        <Collapsible
                                            className="col-span-6"
                                            trigger={
                                                <button className="w-full flex flex-wrap text-left py-3">
                                                    <div className="flex-1">
                                                        <BodyText
                                                            font="subheading"
                                                            className="uppercase tracking-widest"
                                                            fontSize="18px"
                                                        >
                                                            {link.title}
                                                        </BodyText>
                                                    </div>
                                                    <div className="ml-10 self-center">
                                                        <CaretDownIcon width="12" />
                                                    </div>
                                                </button>
                                            }
                                            key={link._uid}
                                        >
                                            <SubMenu link={link} closeMenu={closeMenu} />
                                        </Collapsible>
                                    );
                                } else {
                                    return (
                                        <Link
                                            key={link._uid}
                                            href={link.url || '#'}
                                            onClick={closeMenu}
                                        >
                                            <BodyText
                                                font="subheading"
                                                as="span"
                                                className="block uppercase tracking-widest py-3"
                                                fontSize="18px"
                                            >
                                                {link.title}
                                            </BodyText>
                                        </Link>
                                    );
                                }
                            })}
                        </div>
                    )}
                </div>
            </Menu>
        </Drawer>
    ) : null;
};

const SubMenu = ({ closeMenu, link, className }) => {
    return (
        <div key={link._uid} className={`submenu flex-1${className ? ` ${className}` : ''}`}>
            {link?.sublinks.map(({ _uid, url, title, image, ...sublink }) => (
                <Fragment key={_uid}>
                    <Link href={url || '#'} onClick={closeMenu} className="block py-3">
                        {image?.filename !== '' && (
                            <Image
                                className="mb-3 w-full max-w-md"
                                src={image.filename}
                                alt={image?.alt || title ? `${title} - featured image.` : ''}
                                width={900}
                                height={900}
                            />
                        )}
                        <span className="flex items-center">
                            <BodyText font="subheading" as="span">
                                {title}
                            </BodyText>
                        </span>
                    </Link>
                    {sublink?.sublinks?.length > 0 && (
                        <ul className="submenu">
                            {sublink.sublinks.map(({ title, url, _uid, ...subSublink }) => (
                                <li key={_uid}>
                                    <Link
                                        href={url || '#'}
                                        className="flex items-center py-3"
                                        onClick={closeMenu}
                                    >
                                        <BodyText font="subheading" as="span">
                                            {title}
                                        </BodyText>
                                    </Link>
                                    {subSublink?.sublinks?.length > 0 && (
                                        <SubMenu
                                            link={subSublink}
                                            closeMenu={closeMenu}
                                            className="ml-5"
                                        />
                                    )}
                                </li>
                            ))}
                        </ul>
                    )}
                </Fragment>
            ))}
        </div>
    );
};

SubMenu.propTypes = {
    closeMenu: PropTypes.func,
    link: PropTypes.object,
    className: PropTypes.string,
};

const FormContainer = styled.div`
    input::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: rgb(var(--primary-button-bg));
        opacity: 1; /* Firefox */
    }

    input:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: rgb(var(--primary-button-bg));
    }

    input::-ms-input-placeholder {
        /* Microsoft Edge */
        color: rgb(var(--primary-button-bg));
    }

    .aa-Form {
        background-color: transparent;
        border: 0;
        &:focus-within {
            box-shadow: none;
        }
    }
    .aa-Input {
        width: 100%;
        background: transparent;
        color: rgb(var(--primary-button-bg));
        border-bottom: 1px solid currentcolor;
        outline: none;
        letter-spacing: inherit;
        padding: 16px 28px 16px 0px;
        height: auto;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        &:focus {
            outline: none;
        }
        &:-webkit-autofill {
            -webkit-box-shadow: 0 0 0 36px #1e1e1e inset;
            -webkit-text-fill-color: #fff;
        }
    }
    .aa-ClearButton {
        display: none;
    }
    .aa-SubmitButton {
        position: absolute;
        right: 0;
        top: 0;
        height: 100%;
        display: flex;
        -webkit-box-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        align-items: center;
        padding: 0 12px;
        transition: all 0.2s linear 0s;
        outline: none;
        cursor: pointer;
        color: rgb(var(--primary-button-bg));
    }
`;

const SearchForm = () => {
    const { setSidebarMenuOpen } = useContext(SiteContext);

    const searchClient = algoliasearch(
        process.env.NEXT_PUBLIC_ALGOLIA_APP_ID,
        process.env.NEXT_PUBLIC_ALGOLIA_PUBLIC_API_KEY
    );

    const { analytics } = useConsentState();

    return (
        <FormContainer className="tracking-widest">
            <InstantSearch
                indexName="shopify_products"
                searchClient={searchClient}
                {...{
                    insights: isAlgoliaAnalyticsEnabled()
                        ? {
                              useCookie: analytics,
                          }
                        : false,
                }}
                future={{ preserveSharedStateOnUnmount: true }}
            >
                <Configure
                    clickAnalytics={isAlgoliaAnalyticsEnabled()}
                    analytics={isAlgoliaAnalyticsEnabled()}
                    filters="NOT meta.accentuate.is_sample:'true'"
                />
                <Autocomplete
                    searchClient={searchClient}
                    placeholder="SEARCH..."
                    detachedMediaQuery="none"
                    classNames={{
                        panel: 'aa-Panel--searchSidebar',
                        form: 'aa-Form--searchSidebar',
                        input: 'aa-input--searchSidebar',
                    }}
                    modal={'sidebar'}
                    closeModal={setSidebarMenuOpen}
                />
            </InstantSearch>
        </FormContainer>
    );
};

export default SidebarMenu;
