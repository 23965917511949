import PropTypes from 'prop-types';
import { createElement, Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { createRoot } from 'react-dom/client';
import { usePagination, useSearchBox } from 'react-instantsearch';
import { autocomplete } from '@algolia/autocomplete-js';
import { createQuerySuggestionsPlugin } from '@algolia/autocomplete-plugin-query-suggestions';
import { createLocalStorageRecentSearchesPlugin } from '@algolia/autocomplete-plugin-recent-searches';
import '@algolia/autocomplete-theme-classic';
import { useRouter } from 'next/router';
import { isAlgoliaAnalyticsEnabled } from 'helpers/algolia';
import { useConsentState } from 'hooks/useConsentState';

const Autocomplete = ({
    defaultRefinement,
    searchClient,
    className,
    modal = null,
    closeModal = () => {},
    ...autocompleteProps
}) => {
    const router = useRouter();

    const autocompleteContainer = useRef(null);
    const panelRootRef = useRef(null);
    const rootRef = useRef(null);

    const { refine: setQuery } = useSearchBox();
    const { refine: setPage } = usePagination();

    const [instantSearchUiState, setInstantSearchUiState] = useState({ query: defaultRefinement });
    const debouncedSetInstantSearchUiState = useCallback(
        debounce_leading(setInstantSearchUiState, 500),
        []
    );

    const { analytics } = useConsentState();

    useEffect(() => {
        setQuery(instantSearchUiState.query);
        setPage(0);
    }, [instantSearchUiState]);

    const plugins = useMemo(() => {
        const recentSearches = createLocalStorageRecentSearchesPlugin({
            key: 'instantsearch',
            limit: 3,
            transformSource({ source }) {
                return {
                    ...source,
                    onSelect({ item }) {
                        setInstantSearchUiState({
                            query: item.title,
                        });
                    },
                };
            },
        });

        const querySuggestionsPlugin = createQuerySuggestionsPlugin({
            searchClient,
            indexName: 'shopify_products_query_suggestions',
            getSearchParams() {
                return recentSearches.data?.getAlgoliaSearchParams({
                    hitsPerPage: 8,
                });
            },
        });

        return [recentSearches, querySuggestionsPlugin];
    }, []);

    useEffect(() => {
        if (!autocompleteContainer.current) {
            return;
        }

        const autocompleteInstance = autocomplete({
            ...autocompleteProps,
            container: autocompleteContainer.current,
            initialState: { query: defaultRefinement },
            plugins,
            openOnFocus: true,
            onReset() {
                setInstantSearchUiState({ query: '' });
            },
            onSubmit({ state }) {
                setInstantSearchUiState({
                    query: state.query,
                });

                router.push(`/search?q=${state.query}`);

                if (modal === 'search') {
                    closeModal(modal);
                    return;
                }
                if (modal === 'sidebar') {
                    closeModal();
                    return;
                }
            },
            renderer: { createElement, Fragment, render: () => {} },
            render({ children }, root) {
                if (!panelRootRef.current || rootRef.current !== root) {
                    rootRef.current = root;

                    panelRootRef.current?.unmount();
                    panelRootRef.current = createRoot(root);
                }

                panelRootRef.current.render(children);
            },
            ...(isAlgoliaAnalyticsEnabled() && {
                insights: {
                    useCookie: analytics,
                },
            }),
        });

        return () => autocompleteInstance.destroy();
    }, [plugins]);

    return <div className={className} ref={autocompleteContainer} />;
};

Autocomplete.propTypes = {
    defaultRefinement: PropTypes.string,
    searchClient: PropTypes.object,
    modal: PropTypes.string,
    closeModal: PropTypes.func,
    className: PropTypes.string,
};

export default Autocomplete;

const debounce_leading = (func, timeout = 300) => {
    let timer;
    return (...args) => {
        if (!timer) {
            func.apply(this, args);
        }
        clearTimeout(timer);
        timer = setTimeout(() => {
            timer = undefined;
        }, timeout);
    };
};
